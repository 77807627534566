<template>
  <div>
    <v-card>
      <v-card-title class="dialogColor white--text">
        <span class="headline">Pending Invoice</span>
        <v-spacer></v-spacer>
        <v-btn dark text @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="invoice">
        <template>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="10%" class="text-left  subtitle-1 font-weight-bold">Money In</th>
                  <th class="text-left"></th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in invoice.MoneyIn" :key="item.name">
                  <td v-if="item.TransactionDate !== null">{{ item.TransactionDate | formatDate }}</td>
                  <td v-else>Uncleared</td>
                  <td>{{ item.TransactionDescription }}</td>
                  <td>{{ item.DisplayTransactionAmount }}</td>
                </tr>
                <tr class="font-weight-bold">
                  <td></td>
                  <td class="text-right">Total</td>
                  <td>{{invoice.DisplayMoneyInAmount}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <template>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="10%" class="text-left  subtitle-1 font-weight-bold">Money Out</th>
                  <th class="text-left"></th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in invoice.MoneyOut" :key="item.name">
                  <td>{{ item.TransactionDate | formatDate }}</td>
                  <td>{{ item.TransactionDescription }}</td>
                  <td>{{ item.DisplayTransactionAmount }}</td>
                </tr>
                <tr class="font-weight-bold">
                  <td></td>
                  <td class="text-right">Total</td>
                  <td>{{invoice.DisplayMoneyOutAmount}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <template>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="10%" class="text-left"></th>
                  <th class="text-left"></th>
                  <th width="10%" class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="font-weight-bold">
                  <td></td>
                  <td class="text-right title">Invoice Total</td>
                  <td class="title">{{invoice.DisplayTotalInvoiceAmount}}</td>
                </tr>
              </tbody>  
              </template>
            </v-simple-table>
          </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'pendingInvoiceCard',
  props: {
    invoice: Object
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style>

</style>