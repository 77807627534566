<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="dialogColor white--text">
        Past invoices
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()" text color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="invoices.length > 0" class="pt-5">
        <div 
          v-for="invoice in invoices" 
          :key="invoice.OID"
          class="mb-3"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex">
              <p class="my-0 mr-5 text-body-1 text-capitalize">{{ invoice.GlobalReference }}</p>
              <p class="my-0 text-body-1">{{ formatDate(invoice.TaxDate) }}</p>
            </div>
            <v-btn 
              @click="downloadInvoice(invoice)" 
              color="secondary" 
              text
            >
              <span class="font-weight-light custom-transform-class text-none">Download</span>
              <v-icon right>mdi-download</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <br />
        <p>This person currently has no historic invoices.</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment/src/moment'

export default {
  name: 'historicInvoices',
  props: {
    invoices: [Array, String]
  },
  data() {
    return {
      loading: false
    }
  },
  created(){
    console.log(this.invoices);
  },
  methods: {
    downloadInvoice(invoice) {
      this.loading = 'secondary'
      this.$store.dispatch('OutgoingPayments/downloadInvoice', invoice.OID)
        .then((response) => {
          this.loading = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL
          fileLink.setAttribute('download', `${invoice.GlobalReference}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    closeDialog() {
      this.$emit('closeDialog')
    },

  }
}
</script>

<style>

</style>