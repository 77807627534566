<template>
  <div>
    <v-container class="py-0">
      <transition name="fade" mode="out-in" appear>
        <PageTitle class="d-block d-sm-none">Outgoing payments</PageTitle>
      </transition>

      <transition name="slide-left-fade" mode="out-in" appear>
        <v-tabs
          v-model="tab"
          class="mt-5 mt-sm-1"
          color="tabColor"
          slider-color="tabColor"
          center-active
          background-color="tertiary"
          show-arrows
          height="55"
        >
          <v-tab ripple>
            Landlords
          </v-tab>
          <v-tab ripple>
            Tenants 
          </v-tab>
          <v-tab>
            Contractors
          </v-tab>
          <v-tab>
            All
          </v-tab>
          <v-spacer></v-spacer>
          <span class="d-none d-sm-block">
            <PageTitle>Outgoing payments</PageTitle>
          </span>
        </v-tabs>
      </transition>

      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <v-tabs-items class="pt-2 tertiary" v-model="tab">
            <v-tab-item>
              <Skeleton v-if="!landlordsToPay"/>
              <OutgoingPaymentsTable
                v-if="landlordsToPay"
                :headers="headers"
                :tableData="landlordsToPay"
                msg="Landlords"
                icon="mdi-account-tie"
              />              
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!tenantsToPay"/>
              <OutgoingPaymentsTable
                v-if="tenantsToPay"
                :headers="headers"
                :tableData="tenantsToPay"
                msg="Tenants"
                icon="mdi-home-account"
              />                     
            </v-tab-item>            
            <v-tab-item>
              <Skeleton v-if="!contractorsToPay"/>
              <OutgoingPaymentsTable
                v-if="contractorsToPay"
                :headers="headers"
                :tableData="contractorsToPay"
                msg="Contractors"
                icon="mdi-tools"
              />                      
            </v-tab-item>  
            <v-tab-item>
              <Skeleton v-if="!allToPay"/>
              <OutgoingPaymentsTable  v-if="allToPay"
                :headers="headers"
                :tableData="allToPay"
                msg="All"
                icon="mdi-person"
              />                      
            </v-tab-item>  
          </v-tabs-items>
        </div>
        
      </transition>
    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {mapGetters} from 'vuex'
import Skeleton from '@/components/SkeletonLoaders/SkeletonLoaderTable.vue'
import OutgoingPaymentsTable from '@/components/OutgoingPayments/OutgoingPaymentsTable.vue'
import PageTitle from '@/components/Slots/PageTitle.vue';

export default {
  components: {
    Skeleton,
    OutgoingPaymentsTable,
    PageTitle
  },
  data() {
    return {
      tab: null,
      Landlords: true,
      Tenants: true,
      Contractors: true,
      All: true,
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'PayeeName',
          sortable: true,
        },
        { text: 'Amount', value: 'PaymentAmount', sortable: true, sort: (a, b) => b - a},
        { text: 'Last payment', value: 'LastPaymentAmount', sortable: true, sort: (a, b) => b - a, align: 'center'},
        { text: 'Account Number', value: 'BankAccount'},
        { text: 'Payment status', value: 'OnHold', sortable: true},
        { text: 'Actions', value: 'pauseResume', sortable: false },
        { text: '', value: 'actions', sortable: false, align: 'end' },
        // { text: 'Pending invoice', value: 'pending'},
        // { text: 'Past invoices', value: 'download'},
        // { text: 'Support', value: 'Message', sortable: false },
      ],
    }
  },
  created() {
    this.$store.dispatch('fetchOutgoingPaymentsData')
  },
  computed: {
    ...mapState({
      landlordsToPay: state => state.OutgoingPayments.landlordsToPay,
      tenantsToPay: state => state.OutgoingPayments.tenantsToPay,
      contractorsToPay: state => state.OutgoingPayments.contractorsToPay
    }),
    ...mapGetters({
        allToPay: 'OutgoingPayments/getAllOutgoingPayments'
    })
  }
}
</script>

<style scoped>
.panel-content {
  margin: 1rem 0;
}
</style>
