<template>
  <div>
    <v-card flat :loading="loadingTable">
      <v-card-title class="secondary white--text">
        <div class="ma-0">
          <div v-if="tableData" class="d-flex align-center">
            <v-icon large left color="white">{{ icon }}</v-icon>
            <p class="subtitle-1 my-0" v-if="tableData.length === 1">
              {{ tableData.length }} payment
            </p>
            <p class="subtitle-1 my-0" v-else>
              {{ tableData.length }} payments
            </p>
            <v-divider vertical class="mx-3 my-1" color="white"></v-divider>
            <span class="subtitle-1">{{ totalPayments }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <TableSearch
          @input="
            (newText) => {
              search = newText;
            }
          "
        />
      </v-card-title>

      <v-data-table
        class="tableColor"
        :loading="tableLoading"
        sort-by="OnHold"
        sort-desc
        :search="search"
        :headers="headers"
        :items="tableData"
        item-key="PayeeID"
        :items-per-page="10"
        :mobile-breakpoint="mobileBreakpoint"
      >
        <template v-slot:item.PayeeName="{ item }">
          <a class="accent--text" @click="openInfoDialog(item)">{{
            item.PayeeName
          }}</a>
        </template>

        <template v-slot:header.pauseResume="{ header }">
          <v-btn
            @click="pauseAllDialog = true"
            small
            color="secondary"
            width="125"
            depressed
            :disabled="!somePaymentsActive"
          >
            <v-icon small left>mdi-pause</v-icon>
            Pause all</v-btn
          >
        </template>

        <!-- Status column  -->
        <template v-slot:item.OnHold="{ item }">
          <v-chip v-if="item.OnHold" class="px-5 white--text" color="red" small>
            On hold
          </v-chip>
          <v-chip v-else class="white--text" color="green" small>
            Processing
          </v-chip>
        </template>

        <!-- Actions  PAUSE/RESUME btns -->
        <template v-slot:item.pauseResume="{ item }">
          <v-tooltip open-delay="500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                outlined
                height="30"
                width="125"
                @click.stop="
                  pauseModal(item.PayeeName, item.PayeeID, item.OnHold)
                "
                class="px-2 white--text text-capitalize font-weight-light"
                color="secondary"
              >
                <v-icon small left v-if="item.OnHold">mdi-play</v-icon>
                <v-icon small left v-else>mdi-pause</v-icon>
                <span v-if="item.OnHold">Resume</span>
                <span v-else>Pause</span>
              </v-btn>
            </template>
            <span v-if="item.OnHold">Resume this payment.</span>
            <span v-else>Pause this payment.</span>
          </v-tooltip>
        </template>

        <!-- Payment Amount -->
        <template v-slot:item.PaymentAmount="{ item }">
          <span>
            {{ item | returnDisplayAmount }}
          </span>
        </template>

        <!-- Previous Payment Amount -->
        <template v-slot:item.LastPaymentAmount="{ item }">
          <span class="caption font-weight-light" v-if="item.LastPaymentAmount">
            {{ item | returnDisplayLastPaymentAmount }}
          </span>
          <span v-else class="caption font-weight-light">n/a</span>
        </template>

        <!-- View Pending Invoice -->
        <!-- <template v-slot:item.pending="{ item }">
          <v-tooltip open-delay="2500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn 
                v-on="on"
                @click="fetchPendingInvoice(item.PayeeID)" 
                text 
                color="secondary lighten-2"
                width="90"
                :loading="loadingPendingInvoice"
              >
                <v-icon small left color="secondary lighten-3">mdi-open-in-new</v-icon>
                <span class="caption font-weight-light text-capitalize">Open</span>
              </v-btn>
            </template>
            <span>View latest pending invoice details.</span>
          </v-tooltip>
        </template> -->

        <!-- Historic Invoices -->
        <!-- <template v-slot:item.download="{ item }">
          <v-tooltip open-delay="2500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="item.HistoricInvoices.length" 
                v-on="on"
                @click.stop="openHistoricInvoicesDialog(item.HistoricInvoices)" 
                color="secondary"
                text
                small
                class="text-capitalize"
              >
                <v-icon small left color="secondary lighten-3">mdi-download</v-icon>
                <span class="caption font-weight-light text-capitalize mr-1">{{ item.HistoricInvoices.length }}</span>
                <span class="caption font-weight-light text-lowercase" v-if="item.HistoricInvoices.length > 1">invoices</span>
                <span class="caption font-weight-light text-lowercase" v-else>invoice</span>
              </v-btn>
              <p v-else class="my-0 text-center font-weight-light">n/a</p>
            </template>
            <span>View & download the latest invoices.</span>
          </v-tooltip>
        </template> -->

        <!-- Support message dialog with Tooltip -->
        <!-- <template v-slot:item.Message="{ item }" >
          <v-tooltip open-delay="2500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn outlined small @click="openDialog(item)" v-on="on" color="secondary">
                <v-icon small>mdi-comment-text-outline</v-icon>
              </v-btn>
            </template>
            <span>Contact support regarding this payment.</span>
          </v-tooltip>
        </template> -->

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                small
                depressed
                v-bind="attrs"
                v-on="on"
                class="text-capitalize"
              >
                Actions
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            
            <v-list class="py-0 secondary" dark>
              <v-list-item @click="fetchPendingInvoice(item.PayeeID)">
                
                <v-list-item-title
                  >View latest pending invoice</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="fetchAccountSummary(item.PayeeID)">
                <v-list-item-title
                  >View account summary</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item  @click="applyCharge('Charge', item)">
                <v-list-item-title>
                  Apply Charge</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item  @click="applyCharge('Refund', item)">
                <v-list-item-title>
                  Apply Refund</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                @click.stop="openHistoricInvoicesDialog(item.PayeeID)"
              >
                <v-list-item-title>Download latest invoices</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-download</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="openDialog(item)">
                <v-list-item-title
                  >Contact support regarding this payment</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-comment-text-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- Snackbar indicating API request responses -->
      <v-snackbar fixed v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>

    <!-- Pause payment dialog -->
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card :loading="loading">
        <v-card-title class="dialogColor white--text py-2">
          <div v-if="selectedOnHold">
            <!-- <v-icon color="white" left>mdi-play</v-icon> -->
            <span class="title font-weight-light">Resume payment to:</span>
          </div>
          <div v-if="!selectedOnHold">
            <!-- <v-icon color="white" left>mdi-pause</v-icon> -->
            <span class="title font-weight-light">Pause payment to:</span>
          </div>
        </v-card-title>

        <v-card-text>
          <div class="pt-5" v-if="selectedOnHold">
            <p class="text-h5 mt-5 font-weight-bold text-center">
              {{ selectedName }}
            </p>
          </div>
          <div class="pt-5" v-else-if="!selectedOnHold">
            <p class="text-h5 mt-5 font-weight-bold text-center">
              {{ selectedName }}
            </p>
          </div>
        </v-card-text>

        <v-card-actions class="px-6 pb-4 d-flex justify-space-between">
          <v-btn color="red darken-1" outlined @click="closeModal"> No </v-btn>
          <v-btn
            color="green darken-1 white--text"
            @click="pauseAPI(selectedID)"
            width="150"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /Pause payment dialog -->

    <!-- Pause all dialog -->
    <v-dialog
      v-model="pauseAllDialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="headline primary white--text py-2">
          <span>Pause all payments</span>
        </v-card-title>
        <v-card-text class="pt-5">
          <p class="text-h5">Do you want to pause all payments?</p>
        </v-card-text>
        <v-card-actions class="px-6 pb-4 d-flex justify-space-between">
          <v-btn color="red darken-1" outlined @click="pauseAllDialog = false">
            No
          </v-btn>
          <v-btn
            color="green darken-1 white--text"
            @click="pauseAllPayments"
            width="150"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /Pause all dialog -->

    <!-- Pending invoice dialog -->
    <v-dialog v-model="pendingInvoiceDialog" max-width="100%">
      <PendingInvoiceCard
        :invoice="pendingInvoice"
        @closeDialog="closePendingInvoiceDialog"
      />
    </v-dialog>

    <!-- historic invoices -->
    <v-dialog
      v-model="historicInvoiceDialog"
      width="500px"
      transition="dialog-transition"
    >
      <HistoricInvoicesCard
        :invoices="selectedHistoricInvoices"
        @closeDialog="closeHistoricInvoiceDialog"
      />
    </v-dialog>

    <MsgDialog
      :dialog="msgDialog"
      :details="selectedItem.PayeeName"
      :source="source"
      :OID="selectedItem.PayeeID"
      @closeDialog="closeMsgDialog"
      @msgSent="msgSent"
    />

    <UserInfoDialog
      :dialog="infoDialog"
      :name="selectedItem.PayeeName"
      email=""
      number=""
      :fullDetails="selectedItem"
      :selectedProperties="selectedProperties"
      :isLoading="infoLoading"
      @closeDialog="closeInfoDialog"
    />
    <v-dialog v-model="accountSummaryPopup" max-width="100%">
      <PersonAccountPopup
        :entries="accountSummary"
        @closeDialog="closeAccountSummaryPopup"
      />
    </v-dialog>
    <v-dialog v-model="applyChargePopup" max-width="500px">
    <ApplyChargeOrRefundForm
        :actionToApply="chargeAction"
        :personID="selectedItem.PayeeID"
        @closeDialog="closeChargePopup"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import MsgDialog from "@/components/Dialogs/MessageDialog";
import UserInfoDialog from "@/components/Dialogs/UserInfoDialog";
import TableSearch from "@/components/TableComponents/TableSearch.vue";
import HistoricInvoicesCard from "@/components/OutgoingPayments/HistoricInvoicesCard.vue";
import PendingInvoiceCard from "@/components/OutgoingPayments/PendingInvoiceCard.vue";
import PersonAccountPopup from "../BaseComponents/PersonAccountPopup.vue";
import ApplyChargeOrRefundForm from "../PaymentRequests/ApplyChargeOrRefundForm.vue";

export default {
  name: "outgoingPaymentsTable",
  components: {
    MsgDialog,
    UserInfoDialog,
    TableSearch,
    HistoricInvoicesCard,
    PendingInvoiceCard,
    PersonAccountPopup,
    ApplyChargeOrRefundForm
},
  props: ["tableData", "headers", "msg", "icon"],
  data() {
    return {
      source: "Outgoing payments",
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      mobileBreakpoint: 600,
      expanded: [],
      loading: false,
      tableLoading: false,
      loadingTable: false,
      selectedName: "",
      selectedID: "",
      selectedOnHold: null,
      dialog: false,
      search: "",
      historicInvoiceDialog: false,
      selectedHistoricInvoices: "",
      pendingInvoiceDialog: false,
      pendingInvoice: "",
      loadingPendingInvoice: false,
      selectedItem: "",
      msgDialog: false,
      msgText: "",
      infoDialog: false,
      pauseAllDialog: false,
      loadingAccountsSummary: false,
      accountSummary: "",
      accountSummaryPopup: false,
      loadingChargePopup: false,
      chargeAction: "",
      applyChargePopup: false,
      selectedProperties: [],
      infoLoading: false
    };
  },
  computed: {
    totalPayments() {
      const array = this.tableData;
      const payments = array.map((val) => {
        return val.PaymentAmount;
      });
      const total = payments.reduce((acc, val) => acc + val, 0);
      // return total.toLocaleString()
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(total);
    },
    somePaymentsActive() {
      let onePaymentActive = this.tableData.some((payment) => !payment.OnHold);
      return onePaymentActive;
    },
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false;
    },
    fetchPendingInvoice(id) {
      this.loadingPendingInvoice = true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/outgoingPayments/${id}/invoice/pending`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.pendingInvoice = response.data;
          this.pendingInvoiceDialog = true;
          this.loadingPendingInvoice = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchAccountSummary(id) {
      this.loadingPendingInvoice = true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/company/person/${id}/account`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.accountSummary = response.data;
          this.accountSummaryPopup = true;
          this.loadingAccountsSummary = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openHistoricInvoicesDialog(payeeID) {
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/${payeeID}/invoices/historic`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.selectedHistoricInvoices = response.data;
          this.historicInvoiceDialog = true;
        })
        .catch((err) => {
          console.log(err);
        });
      
      
    },
    closeHistoricInvoiceDialog() {
      this.selectedHistoricInvoices = "";
      this.historicInvoiceDialog = false;
    },
    pauseModal(name, id, onHold) {
      this.selectedName = name;
      this.selectedID = id;
      this.selectedOnHold = onHold;
      this.dialog = true;
    },
    closeModal() {
      this.selectedName = "";
      this.selectedID = "";
      this.selectedOnHold = null;
      this.dialog = false;
    },
    pauseAPI(id) {
      this.loading = true;
      this.tableLoading = true;
      this.$store
        .dispatch("OutgoingPayments/pausePayment", id)
        .then(() => {
          this.snackbar = true;
          (this.snackbarText = "Payment Status Updated"),
            (this.snackbarColor = "green");
          this.loading = false;
          this.dialog = false;
          this.$store.dispatch("fetchOutgoingPaymentsData");
          // this.$store.dispatch('fetchDashboardData')
          // this.$store.dispatch('sessionStatus')
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = "err";
          this.snackbarColor = "red";
          this.$store.dispatch("fetchOutgoingPaymentsData");
          this.$store.dispatch("fetchDashboardData");
          this.loading = false;
          this.dialog = false;
          this.tableLoading = false;
        });
    },
    pauseAllPayments() {
      this.tableLoading = true;
      let requests = this.tableData.map((payment) => {
        if (payment.OnHold === false) {
          return this.$store
            .dispatch("OutgoingPayments/pausePayment", payment.PayeeID)
            .then(() => console.log(`Payement ${payment.PayeeID} paused!`));
        }
      });
      Promise.all(requests).then(() => {
        this.$store.dispatch("fetchOutgoingPaymentsData").then(() => {
          if (this.tableData) {
            this.tableLoading = false;
            this.pauseAllDialog = false;
            this.snackbarColor = "green";
            (this.snackbarText = "All payments paused"), (this.snackbar = true);
            this.$store.dispatch("fetchOutgoingPaymentsData");
            // this.$store.dispatch('fetchDashboardData')
          }
        });
      });
    },
    closePendingInvoiceDialog() {
      (this.pendingInvoice = ""), (this.pendingInvoiceDialog = false);
    },
    closeAccountSummaryPopup() {
      (this.accountSummary = ""), (this.accountSummaryPopup = false);
    },
    openDialog(item) {
      this.selectedItem = item;
      this.msgDialog = true;
    },
    closeMsgDialog() {
      this.msgDialog = false;
      this.msgText = "";
    },
    openInfoDialog(item) {
      this.infoLoading=true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/${item.PayeeID}/invoices/properties`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.selectedProperties = response.data;
          this.infoLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.selectedItem = item;
      this.infoDialog = true;
    },
    closeInfoDialog() {
      this.selectedProperties = [];
      this.infoDialog = false;
    },
    msgSent(payload) {
      this.msgDialog = false;
      (this.snackbar = true), (this.snackbarColor = payload.color);
      this.snackbarText = payload.text;
    },
    applyCharge(actionToApply, item){
      this.chargeAction = actionToApply;
      this.selectedItem = item;
      this.applyChargePopup = true;
    },
    closeChargePopup(){
      this.chargeAction = "";
      this.applyChargePopup = false;
    }
  },
  filters: {
    returnDisplayAmount(val) {
      return val.DisplayPaymentAmount;
    },
    returnDisplayLastPaymentAmount(val) {
      return val.DisplayLastPaymentAmount;
    },
  },
  watch: {
    tableData() {
      this.tableLoading = false;
    },
  },
};
</script>
